import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Home = () => {
  return (
    <>
      <div className="homepage">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={swiper => console.log(swiper)}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          <SwiperSlide>
            <div className="slide1">
              <h1 className="h1">Earth Makers</h1>
              <p className="slogan">Is coming...</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide1 blue">
              <h1 className="h1">Earth Makers</h1>
              <p className="slogan">Is coming...</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide1 green">
              <h1 className="h1">Earth Makers</h1>
              <p className="slogan">Is coming...</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide1 orange">
              <h1 className="h1">Earth Makers</h1>
              <p className="slogan">Is coming...</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Home;
