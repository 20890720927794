import React from "react";
import Home from "pages/Home";
import "./main.scss";
import GenericLayout from "layouts/GenericLayout";
import { Header } from "layouts/Header";
import Footer from "layouts/Footer";
import { Routes, Route } from "react-router-dom";

function App() {
  const header = <Header />;
  const footer = <Footer />;
  return (
    <div className="App">
      <GenericLayout header={header} footer={footer}>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="about" element={<About />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} /> */}
        </Routes>
      </GenericLayout>
    </div>
  );
}

export default App;
