import React from "react";
import propTypes from "prop-types";

const GenericLayout = ({ header, footer, children }) => {
  return (
    <div className="generic-layout">
      {header}
      {children}
      {footer}
    </div>
  );
};

GenericLayout.propTypes = {
  header: propTypes.func,
  footer: propTypes.func,
  children: propTypes.children,
};

export default GenericLayout;
