import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import propTypes from "prop-types";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "red",
    zIndex: "100000",
  },
};

const Navigation = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const hendleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const handleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  return (
    <div className="main-nav">
      <div className={`main-nav__mobilebtn`}>
        <span
          onClick={hendleMenu}
          className={`icon icon-${menuIsOpen ? "cerrar" : "abrir"}`}
        ></span>
      </div>
      <MyModal handleModal={handleModal} modalIsOpen={modalIsOpen} />
      <ul
        className={`main-nav__content  main-nav__content--${
          menuIsOpen ? "open" : "close"
        }`}
      >
        {/* <li>
          <div onClick={handleModal}>open modal</div>
        </li> */}
        <li>
          <Link to="/" onClick={hendleMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={hendleMenu}>
            About
          </Link>
        </li>
        <li>
          <Link to="/faqs" onClick={hendleMenu}>
            Faqs
          </Link>
        </li>
        <li>
          <Link to="/services" onClick={hendleMenu}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={hendleMenu}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

const MyModal = ({ handleModal, modalIsOpen }) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={handleModal}
      >
        <div onClick={handleModal}>close</div>
        hello
      </Modal>
    </>
  );
};

MyModal.propTypes = {
  modalIsOpen: propTypes.bool,
  handleModal: propTypes.func,
};

export default Navigation;
